import {listSimulatorCustomDatabase, listSimulatorDatabase} from 'api-lofty';
import React, {useState, useEffect} from 'react';

function OptionsDatabase({
  idEndpoint, dataGenerate, token, idDatabase, type,
}) {
  const [options, setOptions] = useState([]);
  const handleData = async () => {
    try {
      if (type === 'list') {
        const res = await listSimulatorDatabase({
          idEndpoint, token,
        });
        const fieldLabel = dataGenerate?.fieldsDatabase?.find((item) => (
          item.idDatabase === idDatabase && item.isLabel
        ));
        if (fieldLabel) {
          setOptions(res.data.map((item) => ({
            value: item._id,
            name: item[fieldLabel.name],
          })));
        }
      } else if (type === 'customList') {
        const res = await listSimulatorCustomDatabase({
          idEndpoint,
          token,
        });
        const fieldLabel = dataGenerate?.fieldsDatabase?.find((item) => (
          item.idDatabase === idDatabase && item.isLabel
        ));
        if (fieldLabel) {
          setOptions(res.data.map((item) => ({
            value: item._id,
            name: item[fieldLabel.name],
          })));
        }
      }
    } catch (error) {
      // code
    }
  };
  useEffect(() => {
    handleData();
  }, []);
  return (
    <>
      <option value="">
        Seleccione una
      </option>
      {
        options.map((item) => (
          <option key={item.value} value={item.value}>
            {item.name}
          </option>
        ))
      }
    </>
  );
}

export default OptionsDatabase;
